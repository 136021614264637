import React from 'react'
import Nav from './Nav'
import { Container, Row, Col } from 'react-bootstrap';
import ClientForm from './ClientForm';


export default function AddClient() {
  return (
    <>
            <Container fluid>
                <Row>
                    <Col>
                        <Nav />
                    </Col>
                    <Col xs={10}>
                      <ClientForm title={'Add a Client'} subtitle={'Add a new client to the tool'} />
                    </Col>
                </Row> 
            </Container>
        </>
  )
}
