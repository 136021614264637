import React, { useEffect } from 'react'
import Clients from '../services/clients.service'
import ClientListTable from './ClientListTable'
import { Container, Button, Row, Col } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';


export default function ClientList() {
  const [clients, setClients] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  const fetchClients = async () => {
    try {
      let clients = await Clients.getAll()
      
      clients.forEach( (client) => client.label = `${client.firstName} ${client.lastName}`)
      
      setClients(clients)
      
      return setLoading(false)
    } catch (error) {
      setLoading(false)
      return error
    }
  }
  
  useEffect(() => {
    fetchClients()
  }, [])
  
  if (loading) {
    return <div style={{
      paddingTop: '1rem',
      paddingLeft: '2.5rem'
    }}>Loading...</div>
  }


  return (
    <Container style={{
      paddingTop: '1rem',
      paddingLeft: '2.5rem'
    }}>
      <Row>
        <Col>
          <h2>Client List</h2>
          <p>List of all available clients</p>
        </Col>
        <Col>
          <Button variant="primary" style={{float:'right'}} >
            <Link to='/clients/new' style={{color: 'white', textDecoration: 'none'}} >
              Add Client
            </Link>
          </Button>
        </Col>
      </Row>
      <Row>
        <ClientListTable clients={clients} />
        {/* </Col> */}
      </Row>
      <ToastContainer />
    </Container>
  )
}
