import React, { useEffect, useState } from 'react'
import {Form, Container, Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Clients from '../services/clients.service';
import Balance from '../services/balances.service';

export default function ClientForm(props) {
  const [showBalance, setShowBalance] = useState(false);
  const [users, setUsers] = useState([]);
  const [balances, setBalances] = useState([]);
 
  const fetchBalance = async (users) => {
    const { data } = await Balance.getBalance(users);

    // Return complete array of balances by user 
    if (data && data[0]?.balance.info.result) {
      return data[0].balance.info.result;
    }

    return false;
  }

  useEffect(() => {
  if (props.clientId) {
      async function fetchClient(id) {
        try {
          const result = await Clients.getById(id)
          return result
        } catch (error) {
          return error
        }
      }
      fetchClient(props.clientId).then(result => {
        setForm({
          firstName: result.firstName,
          lastName: result.lastName,
          email: result.email,
          exchange: 'ftx',
          apiKey: result.apiKey,
          secret: result.secret,
        })

        // create users object to send request to balance service
        setUsers([{
          id: result.id,
          exchange: result.exchange,
          key: result.apiKey,
          secret: result.secret,
        }])
      })
  }}, [props.clientId])

  // get balance when have users object
  useEffect(() => {
    fetchBalance(users).then(result => {
      if (result) {
        setBalances(result)
        return setShowBalance(true);
      }
    })

  }, [users])

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    exchange: 'ftx',
    apiKey: '',
    secret: '',
  })

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (form.firstName === '' || form.lastName === '' || form.email === '') {
      toast.error('Please fill all fields', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // In case Add page
    if (props.title.match(/add/i)) {
        const data = await Clients.addClient(form)
        if (!data.error) {
          data.message = 'Client added successfully'
          toast.success('Client added successfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        } else { // In case Edit page
        form.id = props.clientId;
        const data = await Clients.editClient(form)
        if (!data.error) {
          data.message = 'Client edited successfully'
          toast.success('Client edited successfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
    }

    setTimeout(() => {
      navigate('/client-manager')
      window.location.reload()
    }, 2000);
  }

  return (
    <Container style={{
      paddingTop: '1rem',
      paddingLeft: '2.5rem'
    }}>
      <Form>
        <h2>{props.title}</h2>
        <p>{props.subtitle}</p>
        <Form.Group className="mb-3" controlId="formFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="First Name"
            value={form.firstName}
            onChange={(e) => setForm({...form, firstName: e.target.value})}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Last Name"
            value={form.lastName}
            onChange={(e) => setForm({...form, lastName: e.target.value})}
          />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            value={form.email}
            onChange={(e) => setForm({...form, email: e.target.value})}
          />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="formExchange">
          <Form.Label>Exchange</Form.Label>
          <Form.Control
            type="text"
            placeholder="ftx"
            value={form.exchange}
            readOnly
            // onChange={(e) => setForm({...form, exchange: e.target.value})}
          />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="formApiKey">
          <Form.Label>API Key</Form.Label>
          <Form.Control
            type="text"
            placeholder="API Key"
            value={form.apiKey}
            onChange={(e) => setForm({...form, apiKey: e.target.value})}
          />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="formSecret">
          <Form.Label>Secret</Form.Label>
          <Form.Control
            type="text"
            placeholder="Secret"
            value={form.secret}
            onChange={(e) => setForm({...form, secret: e.target.value})}
          />
        </Form.Group>
        
        <Button
          className="me-3"
          variant="primary"
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </Button>
        
        <Button
          variant="secondary"
          type="submit"
          onClick={() => navigate('/client-manager')}
        >
          Cancel
        </Button>
      </Form>

    {showBalance &&
      <Container className='mt-3'>
        <h3>Balance</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>COIN</th>
              <th>VALUE</th>
            </tr>

            {balances.map(asset => (
              <tr key={asset.id}>
                <td>{asset.coin}</td>
                <td>{asset.free}</td>
              </tr>
            ))}
          </thead>
        </Table>
      </Container>
    }

      <ToastContainer />
    </Container>
  )
}
