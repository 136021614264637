import axios from 'axios';
const ASSETS_URL = process.env.REACT_APP_CONNECTOR + 'ftx/coins';
const ASSETS_TOKEN = process.env.REACT_APP_ASSETS_TOKEN;
const assets = {
  getAll: async () => {
    try {
      const { data: { response } } = await axios.get(ASSETS_URL,
        {
          headers: {
            token: ASSETS_TOKEN
          }
        }
      )
      return response
    } catch (error){
        return {error: "Error to get profiles"}
    }
  }, 
}

export default assets;
