import React from 'react'
import {Button, Card, Modal} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Clients from '../services/clients.service';


export default function ClientListTable({clients}) {
  const [show, setShow] = React.useState(false);

  const deleteClient = async (id) => {
    const result = await Clients.removeClient(id)
    
    if (!result.error) { 
      setShow(false);
      result.message = "Client deleted successfully";
      toast.success(result.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } else {
      toast.error(result.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }

    setTimeout(() => {
      window.location.reload()
    } , 2000)
  }

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    clients.map((client, i) => {
      return (
        <Card className="mb-3" style={{display: 'flex', flexDirection: 'row'}} key={client.id}>
          <Card.Img variant="bottom" className="m-2" src="abstract-user.png" style={{width: '5%'}} />
          <Card.Body style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Card.Title>{client.firstName} {client.lastName}</Card.Title>
            <div>
              <Button
                variant="secondary"
                className="me-2"
                style={{alignSelf: 'flex-end'}}
              >
                <Link to={`/clients/edit/${client.id}`} style={{textDecoration: 'none', color: '#fff'}}>
                  Edit
                </Link>
              </Button>

              <Button
                variant="danger"
                style={{alignSelf: 'flex-end'}}
                onClick={handleShow}
                // onClick={() => handleDeleteClient(client.id)}
              >
                Delete
              </Button>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <p>Are you sure to delete this client?</p>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                  <Button variant="danger" onClick={() => deleteClient(client.id)}>Confirm</Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Card.Body>
        </Card>
      )})
)
}

