import {Form, FloatingLabel, Container, Button, Row, Col} from 'react-bootstrap';
import Auth from '../services/auth.service';
import React, { useState } from "react";
import Nav from './Nav';
import InvestmentProfile from './InvestmenProfile';

export default function Dashboard(){

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Nav />
                    </Col>
                    <Col xs={10}>
                        <InvestmentProfile />
                    </Col>
                </Row> 
            </Container>
        </>
    );
}