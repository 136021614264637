import { Link } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SidebarFooter, SidebarContent, SidebarHeader} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

export default function Nav() {
    return (
        <ProSidebar style={{position: 'fixed', marginLeft: '-0.75rem'}}>
            <SidebarHeader></SidebarHeader>
            <SidebarContent>
                <Menu iconShape="square">
                    <MenuItem><Link to="/dashboard">Dashboard</Link></MenuItem>
                    <MenuItem><Link to="/client-manager">Client Manager</Link></MenuItem>
                </Menu>
            </SidebarContent>
            <SidebarFooter>
                <Menu>
                    <MenuItem><Link to="/">Logout</Link></MenuItem>
                </Menu>
            </SidebarFooter>
        </ProSidebar>
    );
}