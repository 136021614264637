import axios from 'axios';
const SPOTS_URL = process.env.REACT_APP_CONNECTOR + 'ccxt/bulk/buy-order';
const TOKEN = process.env.REACT_APP_ASSETS_TOKEN;
const BALANCE_URL = process.env.REACT_APP_CONNECTOR + 'ccxt/users/balance';
const Balance = {
  spotTrade: async (users) => {
    try {
      const response = await axios.post(SPOTS_URL,
        {
          users
        },
        {
          headers: {
            token: TOKEN
          }
        }
      )
      return response
    } catch (error) {
      return {error: "Error in Spot transaction"}
    }
  },
  getBalance: async (users) => {
    try {
      const response = await axios.post(BALANCE_URL,
        {
          users
        },
        {
          headers: {
            token: TOKEN
          }
        }
      )
      return response
    } catch (error) {
      return {error: "Error in Spot transaction"}
    }
  }
}

export default Balance;
