import axios from 'axios'
const STRAPI_LOGIN = process.env.REACT_APP_STRAPI + 'auth/local'
const Auth = {
    loginUser: async (form) => {
        try {
            const {data} = await axios.post(STRAPI_LOGIN, {
                identifier: form.email,
                password: form.password,
            })
            return data
        } catch (error){
            return {error: error.response.data.data[0].messages[0].message}
        }
    }
}
export default Auth