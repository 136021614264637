import React, { useState } from 'react';
import Context from './Context';

export default function ContextProvider ({ children }) {
  const [userData, setUserData] = useState({ loginStatus: false });

  return (
    <Context.Provider value={{
      userData,
      setUserData,
    }}>
      {children}
    </Context.Provider>
  )
}