import axios from 'axios'
const STRAPI_PROFILES = process.env.REACT_APP_STRAPI + 'profiles'
const Profiles = {
    getAll: async (form) => {
        try {
            const {data} = await axios.get(STRAPI_PROFILES,
               {
                   headers: {
                       Authorization: 'Bearer ' + window.localStorage.getItem('ventures-finance-app')
                    }
               }
            )
            return data
        } catch (error){
            return {error: "Error to get profiles"}
        }
    },
    getById: async (id) => {
        try {
            const {data} = await axios.get(STRAPI_PROFILES + '/' + id,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('ventures-finance-app')
                        }
                }
            )
            return data 
        } catch (error){
            return {error: "Error to get profile"}
        }
    },
    addProfile: async (newProfile) => {
        const profile = {
            id: newProfile.id,
            name: newProfile.name,
            assets: newProfile.assets,
            active: true,
            published_at: new Date().toISOString,
            clients: newProfile.clients.map( client => client.id ),
        }

            
        try {
            const {data} = await axios.post(STRAPI_PROFILES, profile,
               {
                   headers: {
                       Authorization: 'Bearer ' + window.localStorage.getItem('ventures-finance-app'),
                       'Content-Type': 'application/json'
                    }
               }
            )
            return data
        } catch (error){
            return {error: "Error to add profile"}
        }
    },
    editProfile: async (profile) => {
        try {
            const {data} = await axios.put(STRAPI_PROFILES + '/' + profile.id,
               {
                    name: profile.name,
                    assets: profile.assets,
                    active: profile.active,
                    published_at: new Date().toISOString,
                    clients: profile.clients.map( client => client.id ),
               },
               {
                   headers: {
                       Authorization: 'Bearer ' + window.localStorage.getItem('ventures-finance-app')
                    }
               }
            )
            return data
        } catch (error){
            return {error: "Error to edit profile"}
        }
    },
    deleteProfile: async (id) => {
        try {
            const {data} = await axios.put(STRAPI_PROFILES + '/' + id,
                {
                    active: false,
                },
                {
                   headers: {
                       Authorization: 'Bearer ' + window.localStorage.getItem('ventures-finance-app')
                    }
               }
            )
            return data
        } catch (error){
            return {error: "Error to delete profile"}
        }
    }
}
export default Profiles