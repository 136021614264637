import {Form, FloatingLabel, Container, Button, Row, Col} from 'react-bootstrap';
import Auth from '../services/auth.service';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import Context from '../context/Context';

export default function Login(){
    const { userData, setUserData } = useContext(Context);
    // Define form model
    const [form, setForm] = useState({
        email: '',
        password: ''
    })
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = await Auth.loginUser(form)

        if(!data.error && data.user.blocked === false){
            const user = {
                email: data.user.email,
                name: data.user.username,
                role: data.user.role.id,
            };

            const token = data.jwt;

            // Set to localstorage
            setUserData({ ...userData, loginStatus: true, token, user })
            window.localStorage.setItem('ventures-finance-app', token)
            navigate('/dashboard')
        } else {
            toast.error(data.error, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs lg="1"></Col>
                <Col md="auto">
                    <Form style={{marginTop: '4rem'}} onSubmit={handleSubmit}>
                        <h2 style={{fontWeight: 'lighter'}}>Ventures Finance</h2>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Usuario"
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="text" 
                                    placeholder="name@example.com" 
                                    value={form.email} 
                                    onChange={e => setForm(prevState => ({...prevState, email: e.target.value }))}
                                    />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingPassword" label="Clave">
                                <Form.Control 
                                    type="password" 
                                    placeholder="Clave" 
                                    value={form.password}
                                    onChange={e => setForm(prevState => ({...prevState, password: e.target.value }))}
                                />
                            </FloatingLabel>
                            <Button className="col-12" type="submit" style={{marginTop: '1rem'}}>Ingresar</Button>
                    </Form>
                </Col>
                <Col xs lg="1"></Col>
            </Row> 
            <ToastContainer />
        </Container>
    );
}