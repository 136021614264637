import React from 'react'
import Nav from './Nav'
import { Container, Row, Col } from 'react-bootstrap';
import ClientForm from './ClientForm';
import { useParams } from 'react-router-dom';


export default function EditClient() {
  const { id } = useParams();
  return (
    <>
            <Container fluid>
                <Row>
                    <Col>
                        <Nav />
                    </Col>
                    <Col xs={10}>
                      <ClientForm clientId={id} title={'Edit a Client'} subtitle={'Update customer information'} />
                    </Col>
                </Row> 
            </Container>
        </>
  )
}