import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import ContextProvider from './context/ContextProvider';
import Dashboard from './components/Dashboard';
import ClientManager from './components/ClientManager';
import AddClient from './components/AddClient';
import EditClient from './components/EditClient';
import Login from './components/Login';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  useEffect(() => {
    document.title = "Ventures Finance | Dashboard"
  }, []);

  return (
    <ContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />}/>
            <Route path="/dashboard" element={<Dashboard />}/>
            <Route path="/client-manager" element={<ClientManager />}/>
            <Route path="/clients/new" element={<AddClient />}/>
            <Route path="/clients/edit/:id" element={<EditClient />}/>
          </Routes>
      </Router>
    </ContextProvider>
  );
}
export default App;
