import axios from 'axios'
const STRAPI_CLIENTS = process.env.REACT_APP_STRAPI + 'clients'
const Clients = {
    getAll: async (form) => {
        try {
            const {data} = await axios.get(STRAPI_CLIENTS,
               {
                   headers: {
                       Authorization: 'Bearer ' + window.localStorage.getItem('ventures-finance-app')
                    }
               }
            )
            return data
        } catch (error){
            return {error: "Error to get clients"}
        }
    },
    getById: async (id) => {
        try {
            const {data} = await axios.get(STRAPI_CLIENTS + '/' + id,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('ventures-finance-app')
                        }
                }
            )
            return data 
        } catch (error){
            return {error: "Error to get client"}
        }
    },
    addClient: async (newClient) => {
        const client = {
            firstName: newClient.firstName,
            lastName: newClient.lastName,
            email: newClient.email,
            exchange: newClient.exchange,
            apiKey: newClient.apiKey,
            secret: newClient.secret,
            active: true,
        }
        try {
            const {data} = await axios.post(STRAPI_CLIENTS, client,
               {
                   headers: {
                       Authorization: 'Bearer ' + window.localStorage.getItem('ventures-finance-app'),
                       'Content-Type': 'application/json'
                    }
               }
            )
            return data
        } catch (error){
            return {error: "Error to add client"}
        }
    },
    editClient: async (client) => {
        try {
            const {data} = await axios.put(STRAPI_CLIENTS + '/' + client.id,
                client,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('ventures-finance-app'),
                        'Content-Type': 'application/json'
                     }
                }
            )
            return data
        } catch (error){
            return {error: "Error to edit client"}
        }
    },
    removeClient: async (id) => {
        try {
            const {data} = await axios.delete(STRAPI_CLIENTS + '/' + id,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('ventures-finance-app')
                     }
                }
            )
            return data
        } catch (error){
            return {error: "Error to remove client"}
        }
    }
}
export default Clients