import {Form, FloatingLabel, Container, Button, Row, Col, Alert, Modal, Spinner} from 'react-bootstrap';
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select'
import Profiles from '../services/profiles.service'
import Clients from '../services/clients.service'
import Resources from '../services/resources.service'
import Auth from '../services/auth.service';
import Balance from '../services/balances.service';
import checkLoginStatus from '../services/checkLoginStatus';
import Context from '../context/Context';

export default function InvestmentProfile(){
    const { userData, setUserData } = useContext(Context);
    const [arrProfiles, setArrProfiles] = useState([])
    const [clients, setClients] = useState([])
    const [options, setOptions] = useState([])
    const [showList, setShowList] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [showAuthModal, setShowAuthModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [edit, setEdit] = useState(false)
    const [form, setForm] = useState({
        name: '',
        asset: '',
        percent: '',
        client: '',
        exchange: '',
        key: '',
        secret: '',
    })
    const [clientProfile, setClientProfile] = useState({
        id: '',
        name: '',
        assets: [],
        active: false,
        published_at: "",
        clients: [],
    })
    
    const [emailForm, setEmailForm] = useState({
        email: '',
        password: '',
        })
    
    const navigate = useNavigate();

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleCloseAuthModal = () => setShowAuthModal(false);

    const handleAddProfile = () => {
        setForm({
            name: '',
            asset: '',
            percent: '',
            client: '',
            exchange: '',
            key: '',
            secret: '',
        })
        setClientProfile({
            id: '',
            name: '',
            assets: [],
            active: false,
            published_at: "",
            clients: [],
        })
        setShowList(false)
        setEdit(false)
    }

    const toastSuccess = (message, time = 2000) => {
        toast.success(message, {
            position: "top-right",
            autoClose: time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const toastError = (message, time = 2000) => {
        toast.error(message, {
            position: "top-right",
            autoClose: time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleCancelForm = () => {
        setShowList(true)
        setEdit(false)
    }

    const verifyPercent = (profile) => {
        let sumPercents = 0
        profile.assets.forEach((asset) => {
            sumPercents += Number(asset.percent)
        })
        
        const data ={
            message: "",
            error: "Sum of assets must be 100%",
        }

        if(sumPercents !== 100){
            toastError(data.error)
            return false;
        }
        return true;
    }
        
    const handleSubmit = async (e) => {
        e.preventDefault()

        const correctPercent = verifyPercent(clientProfile)

        if(!correctPercent) return

        let data = null;

        if (clientProfile.id) {
            data = await Profiles.editProfile(clientProfile)
        } else {
            data = await Profiles.addProfile(clientProfile)
        }

        if(!data.error){
            data.message = "Profile created successfully"
            setForm({ name: '', asset: '', percent: ''});
            setClientProfile({
                name: '',
                assets: [],
                active: false,
                published_at: "",
                clients: [],
            });
            toastSuccess(data.message);
        } else {
            toastError(data.error)
        }

        setTimeout(() => {
            window.location.reload()
        }, 2000);
    }

    const handleEditProfile = async (profile) => {
        const data = profile;
        // const data = await Profiles.getById(profile.id)
        setShowList(false)
        setEdit(true)
        setForm({ name: data.name, asset: data.asset, percent: data.percent})
        setClientProfile({
            id: data.id,
            name: data.name,
            assets: data.assets,
            active: data.active,
            published_at: data.published_at,
            clients: data.clients.map( client => ({
                id: client.id,
                name: `${client.firstName} ${client.lastName}`,
                exchange: client.exchange,
                key: client.apiKey,
                secret: client.secret,
                baseAsset: 'USDT',
            })),
        })
    }

    const removeProfile = async (profile) => {
        const data = await Profiles.deleteProfile(profile.id)
        if(!data.error){
            setShowModal(false)
            data.message = "Profile deleted successfully"
            toastSuccess(data.message);
        } else {
            data.error = "Error deleting profile"
            toastError(data.error);
        }

        setTimeout(() => {
            window.location.reload()
        }, 2000);
    }

    const handleApply = async () => {
        setShowAuthModal(true)
    }

    const spotTrade = async (users) => {
        const response = await Balance.spotTrade(users)
        response.data.forEach(spot => {
            Object.values(spot.stackOrder).forEach(order => {
                const asset = order.ticker.symbol.split('/')[0]

                if(order.success) {
                    const message = `${asset} trade executed successfully`
                    setLoading(false)
                    toastSuccess(message)
                } else {
                    const message = `${asset} trade failed`
                    setLoading(false)
                    toastError(message)
                }
            })
        })
        setShowAuthModal(false)
    }

    const buildUsers = (profile) => {
        const users = []
        profile.clients.forEach((client) => {
            users.push({
                id: client.id,
                name: client.name,
                exchange: client.exchange,
                key: client.key,
                secret: client.secret,
                assets: profile.assets,
                baseAsset: 'USDT',
            })
        })
        return users
    }

    const handleAuth = async (profile) => {
        setLoading(true)

        const data = await Auth.loginUser(emailForm)

        if(!data.error){
            // Build users object to send to spotTrade
            const users = buildUsers(profile)
            console.log("🚀 ~ file: InvestmenProfile.js ~ line 242 ~ handleAuth ~ users", users)

            // Generate spot trade
            await spotTrade(users)

        } else {
            setLoading(false)
            toastError(data.error)
            setShowAuthModal(false)
        }
    }

    useEffect( () => {
        async function fetchData() {
            // Get profiles
            const profiles = await Profiles.getAll()
                .then(result => result.filter(profile => profile.active))
                .catch(err => ({error: err}))

            // Get clients
            let clients  = await Clients.getAll()

            // Get assets
            const assets = await Resources.getAll()

            if (profiles.error || clients.error || assets.error) {
                navigate('/');
            }

            // Convert for options usage
            clients.forEach( item =>  {
                item.label = item.firstName + ' ' + item.lastName
            })

            setClients(clients)
            setArrProfiles(profiles)
            setOptions(assets)
        }
        fetchData()

        // check if user is logged in and update context
        const userStatus = checkLoginStatus();
        if(userStatus) {
            setUserData(userStatus)
        }
    },[])

    useEffect( () => {
        setClientProfile( (prevState) => ({...prevState, name: form.name}))
    }, [form])

    return (
        <>
            <Container style={{
                paddingTop: '1rem',
                paddingLeft: '2.5rem'
            }}>
                <Row>
                    <Col>
                        <h2 style={{marginBottom: 0}}>Investment Profile</h2>
                        <p>Current investment profile</p>
                    </Col>
                    {showList &&
                    (<Col>
                        <Button 
                            style={{float:'right'}}
                            onClick={handleAddProfile}
                        >Add Profile</Button>
                    </Col>)}
                </Row>

                {/* Si es visible la lista */}
                {showList && (<Row>
                    {
                        arrProfiles.map( (item, i) => {
                            return (
                                <Alert variant="secondary" key={i}>
                                    <p style={{marginBottom: 0}}>{item.name}</p>
                                    <small>{item.clients.length} clients</small>
                                    <Button
                                        variant="secondary"
                                        style={{float:'right'}}
                                        onClick={() => handleEditProfile(item)}
                                    >
                                        Editar
                                    </Button>
                                </Alert>
                            )
                        })
                    }
                </Row>)}

                {/* Si es visible el formulario */}
                {
                    !showList && (
                        <Form>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Profile name"
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="text" 
                                    placeholder="Profile name" 
                                    value={form.name} 
                                    onChange={e => {
                                        setForm(prevState => ({...prevState, name: e.target.value }))
                                    }}
                                    />
                            </FloatingLabel>

                            <AssetForm
                                options={options}
                                form={form}
                                setForm={setForm}
                                setClientProfile={setClientProfile}
                                clientProfile={clientProfile}
                            />

                            <ClientForm
                                options={clients}
                                setClientProfile={setClientProfile}
                                clientProfile={clientProfile}
                                form={form}
                                setForm={setForm}
                            />

                            <div style={{textAlign: 'right'}}>
                                <Button
                                    variant="success"
                                    style={{marginRight: '0.5rem'}}
                                    onClick={handleSubmit}
                                >
                                    Save
                                </Button>

                                <Button
                                    variant="secondary"
                                    style={{marginRight: '0.5rem'}}
                                    onClick={handleCancelForm}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant="primary"
                                    style={{marginRight: '0.5rem'}}
                                    onClick={handleApply}
                                >
                                    Apply
                                </Button>

                                {edit &&
                                    <Button variant="danger" onClick={handleShowModal}>Delete</Button>}
                            </div>

                            {/* Edit Modal */}
                            <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Are you sure?</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <p>Are you sure to delete this profile?</p>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
                                    <Button variant="danger" onClick={() => removeProfile(clientProfile)}>Confirm</Button>
                                </Modal.Footer>
                            </Modal>

                            {/* Auth Spot Modal */}
                            <Modal show={showAuthModal} onHide={handleCloseAuthModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Verify it's you</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <p>Confirm your login details to allow the operation</p>
                                </Modal.Body>

                                <Container>
                                    <Form>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email"
                                                value={emailForm.email}
                                                onChange={e => {
                                                    setEmailForm(prevState => ({...prevState, email: e.target.value }))
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword" className='mt-3'>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Enter password"
                                                value={emailForm.password}
                                                onChange={e => {
                                                    setEmailForm(prevState => ({...prevState, password: e.target.value }))
                                                }}
                                            />
                                        </Form.Group>
                                    </Form>
                                </Container>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseAuthModal} disabled={loading}>
                                    {loading && 
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />}
                                        Cancel
                                    </Button>

                                    <Button variant="danger"onClick={() => handleAuth(clientProfile)} disabled={loading}>
                                    {loading &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />}
                                        Confirm
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>

                    )
                }
                <ToastContainer />
            </Container>
        </>
    );
}

export function AssetForm (props) {
    const { options, form, setForm, setClientProfile, clientProfile } = props;

    return (
        <div style={{marginBottom: '1rem'}}>
            <h4>Assets</h4>
            <div className="row">
                <div className="col-10">
                    <Select
                        options={options}
                        name={form.asset}
                        onChange={(e) => setForm(prevState => ({...prevState, asset: e.value }))}
                    />
                </div>
                <div className="col-1">
                    <input
                        className="form-control"
                        placeholder="%"
                        name={form.percent}
                        onChange={(e) => setForm(prevState => ({...prevState, percent: e.target.value }))}
                    />
                </div>
                <div className="col-1">
                    <Button onClick={(e) => setClientProfile(prevState => (
                        {
                            ...prevState,
                            assets: [
                                ...prevState.assets,
                                {
                                    name: form.asset,
                                    percent: form.percent
                                }
                            ]
                        }
                    ))}
                    >
                        Add
                    </Button>
                </div>
            </div>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Asset</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientProfile.assets.map( (item, i) => {
                            return (
                                <tr key={i}>
                                    <td>{item.name}</td>
                                    <td>{item.percent}</td>
                                    <td>
                                        <Button
                                            variant="danger"
                                            onClick={(e) => setClientProfile(prevState => ({
                                                ...prevState,
                                                assets: prevState.assets.filter( (item, index) => index !== i)
                                            }))}
                                        >
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export function ClientForm (props){
    const { options, setClientProfile, clientProfile, form, setForm } = props;
    
    return (
        <div style={{marginBottom: '1rem'}}>
            <h4>Clients</h4>
            <div className="row">
                <div className="col-11">
                    <Select
                        options={options}
                        name={form.client}
                        onChange={(e) => setForm(prevState => (
                            {
                                ...prevState,
                                client: e.label,
                                clientId: e.id,
                                exchange: e.exchange,
                                key: e.apiKey,
                                secret: e.secret
                            }
                            ))}
                    />
                </div>
                <div className="col-1">
                    <Button onClick={(e) => setClientProfile(prevState => (
                        {
                            ...prevState,
                            clients: [
                                ...prevState.clients,
                                {
                                    id: form.clientId,
                                    name: form.client,
                                    exchange: form.exchange,
                                    key: form.key,
                                    secret: form.secret,
                                    baseAsset: 'USDT',
                                }
                            ]
                        }
                    ))}
                    >
                        Add
                    </Button>
                </div>
            </div>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Client</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientProfile.clients.map( (client, i) => {
                            return (
                                <tr key={i}>
                                    <td>{client.name}</td>
                                    <td>
                                        <Button
                                            variant="danger"
                                            onClick={(e) => setClientProfile(prevState => ({
                                                ...prevState,
                                                clients: prevState.clients.filter( (item, index) => index !== i)
                                            }))}
                                        >
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
